$vdb-light: #00AAFF;
$vdb-dark: #334681;

$base-color: $vdb-light;
$action-color: $vdb-light;

$toolbar-background-color: $vdb-dark;
$toolbar-background-color-dark: mix(black, $vdb-dark, 30%);
$toolbar-background-color-light: mix(white, $vdb-dark, 30%);
$secondary-menu-background: $toolbar-background-color-light;
